const URL_SCHEME_REGEX = /^(\w+:)?\/\//;

/**
 * @hidden
 */
export function getUrlScheme(
  url: string,
): string {
  const matches = url.match(URL_SCHEME_REGEX);
  if (matches === null) {
    return null;
  }

  return matches[1] || '';
}
