import { AuthLogoutReason } from './auth.types';

/**
 * Authentication options default values.
 */
export const AUTH_OPTIONS_DEFAULTS = {
  sessionExpirationBeforeTime: 60,      // 1 minute
  sessionPollingIntervalTime: 10 * 60,  // 10 minutes
  sessionAutoRefresh: false,
  redirectUrlUseHash: false,
  identificationFormSkip: false,
  customPageTitle: null,
  customBackgroundStyle: null,
  customHeader: null,
  customHeadline: null,
  customCaption: null,
  customFooter: null,
  i18n: false,
};

/**
 * Auth logout reason - log message map.
 */
export const AUTH_LOGOUT_REASON_LOG_MESSAGES = {
  [AuthLogoutReason.userAction]: 'User action',
  [AuthLogoutReason.sessionExpired]: 'Session expired',
  [AuthLogoutReason.errorOccurred]: 'Error occurred',
};

/**
 * Auth link parent request options default values.
 */
export const AUTH_LINK_PARENT_REQUEST_OPTIONS_DEFAULTS = {
  timeout: 5000, // 5 seconds
};

/**
 * Auth link windows request options default values.
 */
export const AUTH_LINK_WINDOWS_REQUEST_OPTIONS_DEFAULTS = {
  timeout: 5000, // 5 seconds
};
