export interface AuthTimer {
  destroy: () => void;
}

export function createTimer(
  handler: () => void,
  time: number,
  interval = false,
): AuthTimer {
  const timeout = interval ?
    window.setInterval(handler, time) :
    window.setTimeout(handler, time);

  const destroy = () => {
    interval ?
      window.clearInterval(timeout) :
      window.clearTimeout(timeout);
  };

  return { destroy };
}
