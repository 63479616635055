import { useState, useEffect } from "react";
import { Outlet, Link, useLoaderData } from "react-router-dom";
import axios from "axios";

export async function loader({ params }) {
  const issuer_url = `https://api.clusters.npsandbox01.sandbox.rginger.com/api/v1/root`; // TODO: get api root from redux
  return {
    issuer: issuer_url,
    oidc_url: issuer_url + "/.well-known/openid-configuration",
  };
  //return getCluster(params.clusterId);
}

export default function RootTrustDetails(props) {
  const rootDetails = useLoaderData();
  const [accessToken, setAccessToken] = useState(null);

  function useJoinToken(e) {
    const reqPost = new FormData();

    reqPost.append("grant_type", "urn:ietf:params:oauth:grant-type:jwt-bearer");
    reqPost.append("assertion", e.target.assert_token.value);
    e.preventDefault();
    axios
      .post(
        `https://api.clusters.npsandbox01.sandbox.rginger.com/api/v1/root/token`,
        reqPost
      )
      .then((res) => {
        setAccessToken(res.data.access_token);
        e.target.reset();
      });
  }

  return (
    <div>
      <h1>Root Trust Info</h1>
      <Link to="/">Back</Link>
      <dl>
        <dt>oidc issuer</dt>
        <dd>{rootDetails.issuer}</dd>
      </dl>
      <dl>
        <dt>oidc discover document</dt>
        <dd>{rootDetails.oidc_url}</dd>
      </dl>
      <hr />
      <form id="join-token-form" onSubmit={useJoinToken}>
        <div>
          <h3>Cluster Join Token</h3>
          <input type="text" name="assert_token"></input>
          <button type="submit">Generate Access Token</button>
        </div>
        <div>
          <h4>AWS Assume Role</h4>
          {accessToken && (
            <div>
              <textarea cols={120} rows={6}>
                {accessToken}
              </textarea>
              <pre class="shell">
                <code>
                  echo "{accessToken}" &gt; ./cluster_identity_token
                  <br />
                  export
                  AWS_ROLE_ARN=arn:aws:iam::632051273742:role/Example-Spire-ReadOnly
                  <br />
                  export AWS_WEB_IDENTITY_TOKEN_FILE=./cluster_identity_token
                  <br />
                  export AWS_ROLE_SESSION_NAME=cluster-session
                  <br />
                </code>
              </pre>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
