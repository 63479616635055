import {
  PostRequestOptions,
  PostRequestResult,
  PostServer,
  windowPostRequest,
  windowPostServe,
  parentPostRequest,
  parentPostServe,
} from '@navify-platform/post-message';

const IFRAME_LINK_REQUEST_CONNECT = 'connect';
const IFRAME_LINK_REQUEST_CONNECT_TIMEOUT = 2000; // 2 seconds
const IFRAME_LINK_REQUEST_DISCONNECT = 'disconnect';
const IFRAME_LINK_REQUEST_DISCONNECT_TIMEOUT = 2000; // 2 seconds
const IFRAME_LINK_RESPONSE_CONNECTED = 'connected';
const IFRAME_LINK_RESPONSE_DISCONNECTED = 'disconnected';

/**
 * @hidden
 */
export type IframeLinkOptions = {
  disableAsParent?: boolean,
  disableAsWindow?: boolean,
};

/**
 * @hidden
 */
export type IframeLinkHandler = (
  request: any,
  origin: string,
  source: Window,
  extras: {
    ports: MessagePort[],
  },
) => Promise<any>;

/**
 * @hidden
 */
export type IframeLinkRef = {
  origin: string;
  source: Window;
  extras: {
    ports: MessagePort[],
  };
};

/**
 * @hidden
 */
export type IframeLinkRequestOptions = PostRequestOptions;

/**
 * @hidden
 */
export type IframeLinkRequestResult = PostRequestResult;

/**
 * @hidden
 */
export class IframeLink {
  protected parentLink: IframeLinkRef = null;
  protected parentPostServer: PostServer;

  protected windowLinks: IframeLinkRef[] = [];
  protected windowPostServer: PostServer;

  constructor(
    protected asParentHandler: IframeLinkHandler,
    protected asWindowHandler: IframeLinkHandler,
    protected options: IframeLinkOptions = {},
  ) { }

  async init() {
    await this.initAsParent();
    await this.initAsWindow();
  }

  protected async initAsParent() {
    if (this.options.disableAsParent) {
      return null;
    }

    this.windowPostServer = windowPostServe(
      async (request, origin, source, extras) => {
        if (request === IFRAME_LINK_REQUEST_CONNECT) {
          this.windowLinks.push({ origin, source, extras });
          return IFRAME_LINK_RESPONSE_CONNECTED;
        }
        if (request === IFRAME_LINK_REQUEST_DISCONNECT) {
          this.windowLinks.splice(this.windowLinks.findIndex((link) => link.source === source), 1);
          return IFRAME_LINK_RESPONSE_DISCONNECTED;
        }

        if (this.asParentHandler) {
          return await this.asParentHandler(request, origin, source, extras);
        }
        return null;
      },
      null,
    );
  }

  protected async initAsWindow() {
    if (this.options.disableAsWindow || !checkIfIframed()) {
      return null;
    }

    this.parentPostServer = parentPostServe(
      async (request, origin, source, extras) => {
        if (this.asWindowHandler) {
          return await this.asWindowHandler(request, origin, source, extras);
        }
        return null;
      },
    );

    try {
      const { response, origin, source, extras } = await parentPostRequest(
        IFRAME_LINK_REQUEST_CONNECT,
        { timeout: IFRAME_LINK_REQUEST_CONNECT_TIMEOUT },
      );

      if (response === IFRAME_LINK_RESPONSE_CONNECTED) {
        this.parentLink = { origin, source, extras };
      }
    } catch (error) {
      // noop
    }
  }

  async destroy() {
    await this.destroyAsParent();
    await this.destroyAsWindow();
  }

  protected async destroyAsParent() {
    if (this.options.disableAsParent) {
      return null;
    }

    this.windowPostServer.destroy();
  }

  protected async destroyAsWindow() {
    if (this.options.disableAsWindow || !checkIfIframed()) {
      return null;
    }

    this.parentPostServer.destroy();

    try {
      await parentPostRequest(
        IFRAME_LINK_REQUEST_DISCONNECT,
        { timeout: IFRAME_LINK_REQUEST_DISCONNECT_TIMEOUT },
      );
    } catch (error) {
      // noop
    }
  }

  hasParent(): boolean {
    return !!this.parentLink;
  }

  async parentRequest(
    request: any,
    options: IframeLinkRequestOptions = {},
  ): Promise<any> {
    if (this.options.disableAsWindow) {
      return null;
    }

    try {
      const result = await parentPostRequest(request, options);
      return result;
    } catch (error) {
      return error;
    }
  }

  hasWindows(): boolean {
    return !!this.windowLinks.length;
  }

  async windowsRequest(
    request: any,
    options: IframeLinkRequestOptions = {},
  ): Promise<IframeLinkRequestResult[]> {
    if (this.options.disableAsParent) {
      return null;
    }

    return Promise.all(
      this.windowLinks.map(async ({ source }) => {
        try {
          const result = await windowPostRequest(request, source, options);
          return result;
        } catch (error) {
          return error;
        }
      }),
    );
  }
}

/**
 * @hidden
 */
 export const checkIfIframed = (): boolean => {
  return window.parent !== window;
};
