import { Base64 } from 'js-base64';

/**
 * @hidden
 */
export function encodeQuery(query: any): string {
  try {
    const queryEncoded = Base64.encode(JSON.stringify(query));
    return queryEncoded;
  } catch (err) {
    return null;
  }
}

/**
 * @hidden
 */
export function decodeQuery(queryEncoded: string): any {
  try {
    const query: any = JSON.parse(Base64.decode(queryEncoded));
    return query;
  } catch (err) {
    return null;
  }
}
