import { getRequest } from '@navify-platform/http';

import {
  EnvSeed,
  EnvDeploy,
  EnvSource,
  EnvSourceOrigin,
  EnvProp,
  EnvValue,
  EnvSourceType,
} from './env.types';
import {
  ENV_DATA_CONST_ENCODED,
  ENV_DATA_FILE_URI,
  ENV_PROP_SOURCES,
  ENV_PROP_DEFAULTS,
  ENV_CAST_TRUE_REGEX,
  ENV_CAST_FALSE_REGEX
} from './env.consts';
import { getUrlScheme } from './env-url';

export class Env {
  protected seed: EnvSeed;

  protected deployConst: EnvDeploy;
  protected deployFile: EnvDeploy;

  constructor(
    seed: EnvSeed = null,
  ) {
    this.seed = seed;
  }

  protected haveDeployConst() {
    if (this.deployConst === undefined) {
      const deployConst = this.evalDeployConst();
      this.setDeployConst(deployConst);
    }
  }

  protected evalDeployConst() {
    try {
      const deployConst = JSON.parse(atob(ENV_DATA_CONST_ENCODED));
      return deployConst;
    } catch (error) {
      return null;
    }
  }

  protected setDeployConst(
    deployConst: any,
  ) {
    this.deployConst = deployConst;
  }

  protected async haveDeployFile(): Promise<EnvDeploy> {
    if (this.deployFile === undefined) {
      this.deployFile = await this.fetchDeployFile();
    }

    return this.deployFile;
  }

  protected async fetchDeployFile(): Promise<EnvDeploy> {
    try {
      return await getRequest(`${ENV_DATA_FILE_URI}?${Date.now()}`);
    } catch (error) {
      if (error?.faults?.httpNotFound || error?.faults?.httpException) {
        return null;
      }
      throw error;
    }
  }

  async getProp(
    propName: EnvProp,
  ): Promise<EnvValue> {
    const propSources = ENV_PROP_SOURCES[propName];
    if (propSources) {
      return this.pullProp(propSources);
    }

    const defaultSources = [
      { origin: EnvSourceOrigin.Seed, key: propName },
      { origin: EnvSourceOrigin.Default, key: propName },
    ];
    return this.pullProp(defaultSources);
  }

  async pullProp(
    sources: EnvSource[],
  ): Promise<EnvValue> {
    if (!sources || !sources[0]) {
      return undefined;
    }

    const [source, ...otherSources] = sources;
    const { origin, key, type } = source;
    let value;

    switch (true) {
      case origin === EnvSourceOrigin.Seed:
        value = await this.getPropFromSeed(<EnvProp>key);
        break;
      case origin === EnvSourceOrigin.Param:
        value = await this.getParam(key);
        break;
      case origin === EnvSourceOrigin.Default:
        value = await this.getPropFromDefault(key);
        break;
    }

    if (value === undefined) {
      return await this.pullProp(otherSources);
    }

    if (type) {
      value = await this.castType(value, type);
    }

    return value;
  };

  protected async getPropFromSeed(
    propName: EnvProp,
  ): Promise<EnvValue> {
    const seedProps = this.seed && this.seed.props;
    if (seedProps && propName in seedProps) {
      const seedProp = seedProps[propName];
      if (typeof seedProp === 'function') {
        try {
          return await seedProp(propName);
        } catch (error) {
          return undefined;
        }
      }
      return seedProp;
    }

    return undefined;
  }

  async getParam(
    paramName: string,
    type: EnvSourceType = null,
  ): Promise<EnvValue> {
    const seedParam = await this.getParamFromSeed(paramName, type);
    if (seedParam !== undefined) {
      return seedParam;
    }

    const constParam = await this.getParamFromConst(paramName, type);
    if (constParam !== undefined) {
      return constParam;
    }

    const envFileParam = await this.getParamFromFile(paramName, type);
    if (envFileParam !== undefined) {
      return envFileParam;
    }

    return undefined;
  }

  async getStringParam(
    paramName: string,
  ): Promise<string> {
    return <string>(await this.getParam(paramName, EnvSourceType.String));
  }

  async getNumberParam(
    paramName: string,
  ): Promise<number> {
    return <number>(await this.getParam(paramName, EnvSourceType.Number));
  }

  async getBooleanParam(
    paramName: string,
  ): Promise<boolean> {
    return <boolean>(await this.getParam(paramName, EnvSourceType.Boolean));
  }

  async getUrlParam(
    paramName: string,
  ): Promise<string> {
    return <string>(await this.getParam(paramName, EnvSourceType.Url));
  }

  protected async getParamFromSeed(
    paramName: string,
    type: EnvSourceType = null,
  ): Promise<EnvValue> {
    let value = undefined;

    const seedParams = this.seed && this.seed.params;
    if (seedParams && paramName in seedParams) {
      value = seedParams[paramName];
    }

    if (value !== undefined && type) {
      value = await this.castType(value, type);
    }

    return value;
  }

  protected async getParamFromConst(
    paramName: string,
    type: EnvSourceType = null,
  ): Promise<EnvValue> {
    this.haveDeployConst();

    let value = undefined;

    const envConstParams = this.deployConst && this.deployConst.params;
    if (envConstParams && paramName in envConstParams) {
      value = envConstParams[paramName];
    }

    if (value !== undefined && type) {
      value = await this.castType(value, type);
    }

    return value;
  }

  protected async getParamFromFile(
    paramName: string,
    type: EnvSourceType = null,
  ): Promise<EnvValue> {
    await this.haveDeployFile();

    let value = undefined;

    const envFileParams = this.deployFile && this.deployFile.params;
    if (envFileParams && paramName in envFileParams) {
      value = envFileParams[paramName];
    }

    if (value !== undefined && type) {
      value = await this.castType(value, type);
    }

    return value;
  }

  protected async getPropFromDefault(
    propName: string,
  ): Promise<EnvValue> {
    const seedDefaults = this.seed && this.seed.defaults;
    if (seedDefaults && propName in seedDefaults) {
      const seedDefault = seedDefaults[propName];
      if (typeof seedDefault === 'function') {
        return await seedDefault(propName);
      }
      return seedDefault;
    }

    if (propName in ENV_PROP_DEFAULTS) {
      return ENV_PROP_DEFAULTS[propName];
    }

    return undefined;
  }

  protected async castType(
    value: any,
    type: EnvSourceType,
  ): Promise<any> {
    if (type === EnvSourceType.String) {
      return await this.castString(value);
    }
    if (type === EnvSourceType.Number) {
      return await this.castNumber(value);
    }
    if (type === EnvSourceType.Boolean) {
      return await this.castBoolean(value);
    }
    if (type === EnvSourceType.Url) {
      return await this.castUrl(value);
    }
  }

  protected async castString(
    value: any,
  ): Promise<string> {
    return value ? `${value}` : '';
  }

  protected async castNumber(
    value: any,
  ): Promise<number> {
    return value ? parseFloat(value) : 0;
  }

  protected async castBoolean(
    value: any,
  ): Promise<boolean> {
    if (typeof value === 'string') {
      if (value.match(ENV_CAST_TRUE_REGEX)) {
        return true;
      }
      if (value.match(ENV_CAST_FALSE_REGEX)) {
        return false;
      }
    }
    return !!value;
  }

  protected async castUrl(
    value: any,
  ): Promise<string> {
    let url = await this.castString(value);

    const urlScheme = getUrlScheme(url);
    if (urlScheme === null) {
      const urlSchemeAlt = await this.getUrlScheme();
      url = `${urlSchemeAlt}//${url}`;
    }

    return url;
  }

  async getAppAlias(): Promise<string> {
    return <string>(await this.getProp(EnvProp.AppAlias));
  }

  async getUrlScheme(): Promise<string> {
    return <string>(await this.getProp(EnvProp.UrlScheme));
  }

  async getPlatformApiUrl(): Promise<string> {
    return <string>(await this.getProp(EnvProp.PlatformApiUrl));
  }

  async getPlatformAppAlias(): Promise<string> {
    return <string>(await this.getProp(EnvProp.PlatformAppAlias));
  }

  async getProductApiUrl(): Promise<string> {
    return <string>(await this.getProp(EnvProp.ProductApiUrl));
  }

  async getAuthType(): Promise<string> {
    return <string>(await this.getProp(EnvProp.AuthType));
  }

  async getAuthUiUrl(): Promise<string> {
    return <string>(await this.getProp(EnvProp.AuthUiUrl));
  }

  async getAuthOktaIssuer(): Promise<string> {
    return <string>(await this.getProp(EnvProp.AuthOktaIssuer));
  }

  async getAuthOktaClientId(): Promise<string> {
    return <string>(await this.getProp(EnvProp.AuthOktaClientId));
  }

  async getAppdEndUserMonitoring(): Promise<boolean> {
    return <boolean>(await this.getProp(EnvProp.AppdEndUserMonitoring));
  }

  async getAppdEndUserMonitoringKey(): Promise<string> {
    return <string>(await this.getProp(EnvProp.AppdEndUserMonitoringKey));
  }

  async getCfDistributionId(): Promise<string> {
    return <string>(await this.getProp(EnvProp.CfDistributionId));
  }

  async getTenantAlias(): Promise<string> {
    return <string>(await this.getProp(EnvProp.TenantAlias));
  }
}
