import { useState, useEffect } from "react";
import { Outlet, Link, useLoaderData } from "react-router-dom";
import axios from "axios";

export async function getCluster(clusterId) {
  const resp = await axios
    .get(
      `https://api.clusters.npsandbox01.sandbox.rginger.com/api/v1/clusters/${clusterId}`,
      {
        headers: {
          "X-Navify-Tenant": "navify-core-administrators", // TODO: get from redux
        },
        withCredentials: true,
      }
    )
    .then((v) => {
      return v.data;
    });
  return resp;
}

export async function createClusterKey(clusterId) {
  const resp = await axios
    .post(
      `https://api.clusters.npsandbox01.sandbox.rginger.com/api/v1/clusters/${clusterId}/keypair`,
      {},
      {
        headers: {
          "X-Navify-Tenant": "navify-core-administrators", // TODO: get from redux
        },
        withCredentials: true,
      }
    )
    .then((v) => {
      return v.data;
    });
  return resp;
}

export async function getClientJoinToken(clusterId) {
  const resp = await axios
    .post(
      `https://api.clusters.npsandbox01.sandbox.rginger.com/api/v1/clusters/${clusterId}/client/assertion`,
      {},
      {
        headers: {
          "X-Navify-Tenant": "navify-core-administrators", // TODO: get from redux
        },
        withCredentials: true,
      }
    )
    .then((v) => {
      return v.data;
    });
  return resp;
}
export async function getClusterKey(clusterId) {
  const resp = await axios
    .get(
      `https://api.clusters.npsandbox01.sandbox.rginger.com/api/v1/clusters/${clusterId}/keypair`,
      {
        headers: {
          "X-Navify-Tenant": "navify-core-administrators", // TODO: get from redux
        },
        withCredentials: true,
      }
    )
    .then((v) => {
      return v.data;
    });
  return resp;
}

export async function loader({ params }) {
  return getCluster(params.clusterId);
}

export default function ClusterDetails(props) {
  const cluster = useLoaderData();
  const [keyCreated, setKeyCreated] = useState(false);
  const [publicKey, setPublicKey] = useState(null);
  const [joinToken, setJoinToken] = useState(null);

  function createKey() {
    const new_keys = createClusterKey(cluster.cluster_id);
    setKeyCreated(true);
  }

  function createJoinToken() {
    getClientJoinToken(cluster.cluster_id).then((x) => {
      setJoinToken(x.token);
    });
  }

  function getPublicKey() {
    getClusterKey(cluster.cluster_id).then((x) => {
      setPublicKey(x.public_key);
    });
  }

  function clearPublicKey() {
    setPublicKey(null);
  }

  function copyString(value) {
    navigator.clipboard.writeText(value);
  }

  return (
    <div>
      <h1>Cluster</h1>
      <Link to="/">Back</Link>
      <dl>
        <dt>ID</dt>
        <dd>{cluster.cluster_id}</dd>
        <dt>name</dt>
        <dd>{cluster.name}</dd>
        <dt>owner</dt>
        <dd>{cluster.owner}</dd>
        <dt>oidc discover document</dt>
        <dd>
          <span className="url">{cluster.oidc_url}</span>
          <button onClick={() => copyString(cluster.oidc_url)}>COPY</button>
        </dd>
        <dt>issuer url</dt>
        <dd>
          <span className="url">{cluster.iss_url}</span>
        </dd>
      </dl>
      <button onClick={createKey}>Create Cluster Key</button>
      {keyCreated && <div>Key Created!</div>}

      <button onClick={getPublicKey}>Get Cluster Public Key</button>
      {publicKey && (
        <div>
          <button onClick={clearPublicKey}>X</button>
          <br />
          <textarea rows={10} cols={65} readOnly>
            {publicKey}
          </textarea>
        </div>
      )}

      <hr />

      <button onClick={createJoinToken}>Get Cluster Join Token</button>
      {joinToken && (
        <div>
          <textarea rows={10} cols={65} readOnly>
            {joinToken}
          </textarea>
        </div>
      )}
    </div>
  );
}
