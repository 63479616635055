import "./App.css";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Env } from "@navify-platform/env";
import { Auth } from "@navify-platform/auth";

import ClusterList from "./ClusterList.js";
import ClusterDetails, { loader as clusterLoader } from "./ClusterDetails.js";
import RootTrustDetails, { loader as rootTrustLoader } from "./RootTrust";
import CreateCluster from "./CreateCluster";

// TODO: better paramaterize

const platformEnv = new Env({
  props: {
    urlScheme: "https:",
    platformApiUrl: "api.npsandbox01.sandbox.rginger.com",
    authUiUrl: "roche.authapp.npsandbox01.sandbox.rginger.com",
    appAlias: "edge-cluster-registry",
    tenantAlias: "navify-core-administrators",
  },
});

const authConf = {
  env: platformEnv,
};

const auth = new Auth(authConf);

const router = createBrowserRouter([
  {
    path: "/",
    element: <ClusterList />,
  },
  {
    path: "/createCluster",
    element: <CreateCluster />,
  },
  {
    path: "/clusters/:clusterId",
    element: <ClusterDetails />,
    loader: clusterLoader,
  },
  {
    path: "/root/trust",
    element: <RootTrustDetails />,
    loader: rootTrustLoader,
  },
]);

function App() {
  const [profile, setAuthSessionProfile] = useState(null);
  //const [authSessionExpires, setAuthSessionExpires] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    async function fetchData() {
      await auth.init();
      auth.subscribe((event) => {
        // TODO: event processing
      });
      auth.getSession().then((session) => {
        if (session != null) {
          setAuthSessionProfile(session.profile);
          // TODO: refresh before expires
          //setAuthSessionExpires(session.expiresAt);
          setIsAuthenticated(true);
        }
      });
    }
    fetchData();
  }, [isAuthenticated]);

  async function login() {
    await auth.loginRedirect();
    auth.getLoginReturn().then((login_success) => {
      // TODO: process errors
    });
  }

  async function logout() {
    await auth.logoutRedirect();
    setAuthSessionProfile(null);
    //setAuthSessionExpires(null);
    setIsAuthenticated(false);
  }

  return (
    <div className="App">
      <header className="App-header">
        <div>
          {isAuthenticated && (
            <p>
              Welcome, {profile.username}{" "}
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => logout()}
              >
                Logout
              </button>
            </p>
          )}
          {!isAuthenticated && (
            <p>
              Please{" "}
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => login()}
              >
                Login
              </button>
            </p>
          )}
        </div>
        <div>
          {isAuthenticated && (
            <div>
              <RouterProvider router={router} />
            </div>
          )}
        </div>
      </header>
    </div>
  );
}

export default App;
