import { getUrlDetails } from '@navify-platform/url';
import { ExtError } from '@navify-platform/error';
import {
  windowPostConnect,
  parentPostConnect,
  windowPost,
  parentPost,
} from '@navify-platform/post-message';

import { createTimer } from './timer';

export const AUTH_IFRAME_POSTMESSAGE_TYPE_REQUEST = 'iframeRequest';
export const AUTH_IFRAME_POSTMESSAGE_TYPE_RESPONSE = 'iframeResponse';
export const AUTH_IFRAME_POSTMESSAGE_TYPE_FAILURE = 'iframeFailure';

const AUTH_IFRAME_REQUEST_TIMEOUT_DEFAULT = 60000; // 1 minute
const AUTH_IFRAME_REQUEST_ELEMENT_STYLE = 'position: absolute; width: 0; height: 0; border: 0;';
const AUTH_IFRAME_POPUP_ELEMENT_STYLE =
  'position: fixed; left: 0; top: 0; width: 100%; height: 100%; border: 0; z-index: 1000000;';

/**
 * @hidden
 */
export interface IframeRequestOptions {
  origin?: string;
  timeout?: number;
  style?: string;
}

/**
 * @hidden
 */
export async function iframeRequest(
  requestUrl: string,
  requestPayload: any,
  options?: IframeRequestOptions,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const requestOrigin = evalIframeUrlOrigin(options && options.origin || requestUrl);

    const iframeEl = <HTMLIFrameElement>window.document.createElement('iframe');
    iframeEl.setAttribute('style', options && options.style || AUTH_IFRAME_REQUEST_ELEMENT_STYLE);
    window.document.body.appendChild(iframeEl);
    const loadHandler = () => {
      windowPost(
        AUTH_IFRAME_POSTMESSAGE_TYPE_REQUEST,
        requestPayload,
        requestOrigin,
        iframeEl.contentWindow,
      );
      iframeEl.removeEventListener('load', loadHandler, false);
    };
    iframeEl.addEventListener('load', loadHandler, false);
    iframeEl.setAttribute('src', requestUrl);

    const iframeDisconnect = windowPostConnect(
      async (responseType, responsePayload, responseOrigin) => {
        if (responseOrigin === requestOrigin) {
          destroy();
          if (responseType === AUTH_IFRAME_POSTMESSAGE_TYPE_RESPONSE) {
            resolve(responsePayload);
          } else if (responseType === AUTH_IFRAME_POSTMESSAGE_TYPE_FAILURE) {
            window.console.error('Iframe request failure.', {
              requestUrl,
              requestOrigin,
              requestPayload,
              responseOrigin,
              responsePayload,
            });
            reject(responsePayload);
          }
        }
      },
      iframeEl.contentWindow,
    );

    let timer;
    if (!(options && options.timeout === null)) {
      timer = createTimer(
        () => {
          destroy();
          window.console.error('Iframe request timeout.', {
            requestUrl,
            requestOrigin,
            requestPayload,
          });
          reject(new ExtError({ iframeTimeout: true }));
        },
        options && options.timeout || AUTH_IFRAME_REQUEST_TIMEOUT_DEFAULT,
      );
    }

    const destroy = () => {
      if (timer) {
        timer.destroy();
      }
      iframeDisconnect();
      window.document.body.removeChild(iframeEl);
    };
  });
}

/**
 * @hidden
 */
export interface IframePopupOptions {
  origin?: string;
  style?: string;
}

/**
 * @hidden
 */
export async function iframePopup(
  url: string,
  requestPayload: any,
  options?: IframePopupOptions,
): Promise<any> {
  return iframeRequest(
    url,
    requestPayload,
    {
      origin: options && options.origin,
      style: options && options.style || AUTH_IFRAME_POPUP_ELEMENT_STYLE,
      timeout: null,
    },
  );
}

/**
 * @hidden
 */
export interface IframeHandler {
  destroy: () => void;
}

/**
 * @hidden
 */
export function iframeHandle(
  requestHandler: (payload: any, origin: string) => void,
): IframeHandler {
  const parentPostDisconnect = parentPostConnect(
    async (type, requestPayload, requestOrigin) => {
      if (type === AUTH_IFRAME_POSTMESSAGE_TYPE_REQUEST) {
        await requestHandler(requestPayload, requestOrigin);
      }
    },
  );
  return { destroy: parentPostDisconnect };
}

/**
 * @hidden
 */
export function iframeReturn(
  responsePayload: any,
  responseOrigin = '*',
) {
  parentPost(
    AUTH_IFRAME_POSTMESSAGE_TYPE_RESPONSE,
    responsePayload,
    responseOrigin,
  );
}

/**
 * @hidden
 */
export function iframeThrow(
  responseError: any,
  responseOrigin = '*',
) {
  parentPost(
    AUTH_IFRAME_POSTMESSAGE_TYPE_FAILURE,
    responseError,
    responseOrigin,
  );
}

/**
 * @hidden
 */
export interface IframeServer {
  destroy: () => void;
}

/**
 * @hidden
 */
export function iframeServe(
  requestHandler: (payload: any, origin: string) => void,
): IframeServer {
  return iframeHandle(async (requestPayload, requestOrigin) => {
    try {
      const responsePayload = await requestHandler(requestPayload, requestOrigin);
      iframeReturn(responsePayload, requestOrigin);
    } catch (error) {
      iframeThrow(error, requestOrigin);
    }
  });
}

/**
 * @hidden
 */
export const checkIfIframed = (): boolean => {
  return window.parent !== window;
};

/**
 * @hidden
 */
export const evalIframeUrlOrigin = (url: string): string => {
  const { protocol, hostname, port } = getUrlDetails(url);
  const portname = port ? `:${port}` : '';
  const origin = `${protocol}//${hostname}${portname}`;
  return origin;
};
