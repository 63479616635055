import { evalNewRandomKey } from '@navify-platform/random';

import {
  AuthOptions,
  AuthSession,
} from './auth.types';
import {
  encodeQuery,
  decodeQuery,
} from './auth-query';

const SESSION_KEY_LENGTH = 16;

/**
 * @hidden
 */
 export function sessionSet(value: any, id?: string): string {
  const setId = id || evalNewRandomKey(SESSION_KEY_LENGTH);
  const valueEncoded = encodeQuery(value);
  window.sessionStorage[setId] = valueEncoded;
  return setId;
}

/**
 * @hidden
 */
 export function sessionGet(id: string): any {
  const valueEncoded = window.sessionStorage[id];
  const value: any = decodeQuery(valueEncoded);
  return value;
}

/**
 * @hidden
 */
export function evalAuthSessionExpiration(
  authSession: AuthSession,
  authOptions: AuthOptions,
): {
  expiresAt: number,
  expiresIn: number,
  beforeExpiresIn: number,
} {
  const expiresAt = authSession.expiresAt;
  const expiresIn = expiresAt - Math.round(Date.now() / 1000);
  const beforeExpiresIn = expiresIn - authOptions.sessionExpirationBeforeTime;
  return { expiresAt, expiresIn, beforeExpiresIn };
}

/**
 * @hidden
 */
export function validateAuthSession(
  authSession: AuthSession,
  authOptions: AuthOptions,
): AuthSession {
  if (!authSession) {
    return null;
  }

  const { expiresIn } = evalAuthSessionExpiration(authSession, authOptions);

  if (expiresIn <= 0) {
    return null;
  }

  return authSession;
}

/**
 * @hidden
 */
export function immutablyEvalAuthSession(
  authSessionNew: AuthSession,
  authSessionBase: AuthSession,
): AuthSession {
  if (authSessionNew === authSessionBase) {
    return authSessionBase;
  }

  if (
    (!authSessionNew) ||
    (!authSessionBase) ||
    (authSessionNew.expiresAt !== authSessionBase.expiresAt) ||
    (authSessionNew.profile.username !== authSessionBase.profile.username)
  ) {
    return authSessionNew;
  }

  return authSessionBase;
}
