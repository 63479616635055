import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export default function ClusterList(props) {
  const [clusters, setClusters] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://api.clusters.npsandbox01.sandbox.rginger.com/api/v1/clusters/`,
        {
          headers: {
            "X-Navify-Tenant": "navify-core-administrators", // TODO: get from redux
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        const clusters = res.data;
        setClusters(clusters.clusters);
        setLoading(false);
      });
  }, [clusters.clusters]);
  return (
    <div>
      <div>
        <h2>Actions</h2>
        <ul>
          <li>
            <Link to="/createCluster">Create</Link>
          </li>
          <li>
            <Link to="/root/trust">Root Trust Info</Link>
          </li>
        </ul>

        <hr />
      </div>
      <h1>Clusters</h1>
      {!loading && (
        <ul>
          {clusters.map((cluster) => (
            <li key={cluster.cluster_id}>
              <Link to={`clusters/${cluster.cluster_id}`}>{cluster.name}</Link>
            </li>
          ))}
        </ul>
      )}
      {loading && <div>Loading...</div>}
    </div>
  );
}
