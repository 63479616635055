export enum EnvProp {
  AppAlias = 'appAlias',
  UrlScheme = 'urlScheme',
  PlatformApiUrl = 'platformApiUrl',
  PlatformAppAlias = 'platformAppAlias',
  ProductApiUrl = 'productApiUrl',
  AuthType = 'authType',
  AuthUiUrl = 'authUiUrl',
  AuthOktaIssuer = 'authOktaIssuer',
  AuthOktaClientId = 'authOktaClientId',
  AppdEndUserMonitoring = 'appdEndUserMonitoring',
  AppdEndUserMonitoringKey = 'appdEndUserMonitoringKey',
  CfDistributionId = 'cfDistributionId',
  TenantAlias = 'tenantAlias',
}

export enum EnvParam {
  AppAlias = 'APP_ALIAS',
  UrlScheme = 'URL_SCHEME',
  PlatformApiUrl = 'API_GATEWAY_ADDRESS',
  PlatformAppAlias = 'PLATFORM_APP_ALIAS',
  ProductApiUrl = 'PRODUCT_API_GATEWAY_ADDRESS',
  AuthType = 'AUTH_TYPE',
  AuthUiUrl = 'AUTH_UI_ADDRESS',
  AuthOktaIssuer = 'OKTA.ISSUER',
  AuthOktaClientId = 'OKTA.CLIENT_ID',
  AppdEndUserMonitoring = 'APPDYNAMICS_END_USER_MONITORING',
  AppdEndUserMonitoringKey = 'APPDYNAMICS_END_USER_MONITORING_KEY',
  CfDistributionId = 'CF_DISTRIBUTION_ID',
  TenantAlias = 'TENANT_ALIAS',
}

export type EnvParams = Record<string, EnvValue>;

export type EnvValue = number | string | boolean;

export interface EnvSource {
  origin: EnvSourceOrigin,
  key: string,
  type?: EnvSourceType,
}

export enum EnvSourceOrigin {
  Seed = 'seed',
  Param = 'param',
  Default = 'default',
}

export enum EnvSourceType {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Url = 'url',
}

export interface EnvSeed {
  props?: Partial<Record<EnvProp, any>> | Record<string, any>,
  params?: Partial<Record<EnvParam, any>> | Record<string, any>,
  defaults?: Partial<Record<EnvProp, any>> | Record<string, any>,
};

export interface EnvDeploy {
  params: EnvParams,
}
