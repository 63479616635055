import urlParse from 'url-parse';

const URL_PORT_DEFAULT = '80';
const URL_PLUS_REGEXP = /\+/g;

/**
 * @hidden
 */
export function getWindowUrl(): string {
  const url = window.location.href;
  return url;
}

/**
 * @hidden
 */
export function setWindowUrl(
  url: string,
  replace?: boolean,
) {
  if (replace) {
    window.location.replace(url);
  } else {
    window.location.assign(url);
  }
}

/**
 * @hidden
 */
export interface UrlDetails {
  href: string;
  protocol: string;
  hostname: string;
  port: string;
  host: string;
  origin: string;
  pathname: string;
  search: string;
  hash: string;
}

export const URL_PROTOCOL_REGEX = /^([a-z]*:)?\/\//;

/**
 * @hidden
 */
export function getUrlDetails(url: string): UrlDetails {
  if (typeof url !== 'string') {
    return null;
  }

  if (!url.match(URL_PROTOCOL_REGEX)) {
    url = `http://${url}`;
  }

  const urlData = urlParse(url, false);

  let href = urlData?.href || '';
  const protocol = urlData?.protocol || '';
  const hostname = urlData?.hostname || '';
  const port = urlData?.port || '';
  const host = urlData?.host || '';
  const origin = urlData?.origin || '';
  let pathname = urlData?.pathname || '';
  const search = urlData?.query || '';
  const hash = urlData?.hash || '';

  if (!urlCheckEndSlash(url)) {
    href = urlRemoveEndSlash(href);
    pathname = urlRemoveEndSlash(pathname);
  }

  if (href) {
    return {
      href,
      protocol,
      host,
      hostname,
      port,
      search,
      origin,
      pathname,
      hash,
    };
  }

  return null;
}

const URL_END_SLASH_REGEX = /\/+(\?|#|$)/;

/**
 * @hidden
 */
export function urlCheckEndSlash(url: string): boolean {
  if (url) {
    return url.match(URL_END_SLASH_REGEX) !== null;
  }
  return false;
}

/**
 * @hidden
 */
export function urlRemoveEndSlash(url: string): string {
  return url.replace(URL_END_SLASH_REGEX, (...matches: string[]) => matches[1]);
}

/**
 * @hidden
 */
export function urlGetDomain(url: string, levelLimit?: number): string {
  const urlDetails = getUrlDetails(url);
  if (urlDetails) {
    const allLevels = urlDetails.hostname.split('.');
    const levels = levelLimit > 0 ? allLevels.splice(-levelLimit, levelLimit) : allLevels;
    const domain = levels.join('.');
    return domain;
  }
  return null;
}

/**
 * @hidden
 */
export interface UrlParams {
  protocol: string;
  hostname: string;
  port: string;
  search: string;
  pathname: string;
  hash: string;
}

/**
 * @hidden
 */
export function renderUrlParams(urlParams: UrlParams): string {
  const { protocol, hostname, port, search, pathname, hash } = urlParams;
  const portname = (port && port !== URL_PORT_DEFAULT) ? `:${port}` : '';
  return `${protocol}//${hostname}${portname}${pathname}${search}${hash}`;
}

/**
 * @hidden
 */
export function getQueryParams(
  query: string,
  useHash = false,
): Record<string, string> {
  if (!query) {
    return {};
  }

  const queryParams = {};
  query.split('&').forEach((part: string) => {
    if (!part) return;
    const [name, value] = part.split('=');
    const decodedName = useHash ? decodeHashParam(name) : decodeQueryParam(name);
    const decodedValue = (value === undefined) ? null :
      (useHash ? decodeHashParam(value) : decodeQueryParam(value));
    queryParams[decodedName] = decodedValue;
  });

  return queryParams;
}

/**
 * @hidden
 */
 export function getUrlQueryParams(
  url: string,
  useHash = false,
): Record<string, string> {
  const urlDetails = getUrlDetails(url);
  if (urlDetails) {
    const [, query] = (useHash ? urlDetails.hash : urlDetails.search).split('?');
    return getQueryParams(query, useHash);
  }
  return null;
}

/**
 * @hidden
 */
export function getUrlQueryParam(
  url: string,
  paramName: string,
  useHash = false,
): string {
  const queryParams = getUrlQueryParams(url, useHash);
  if (queryParams && paramName in queryParams) {
    return queryParams[paramName];
  }
  return null;
}

/**
 * @hidden
 */
export function renderUrlQueryParams(
  url: string,
  queryParams: Record<string, string>,
  useHash = false,
): string {
  const urlDetails = getUrlDetails(url);
  if (!urlDetails) {
    return null;
  }

  const urlQueryParams = getUrlQueryParams(useHash ? urlDetails.hash : urlDetails.search, useHash);
  const allQueryParams = { ...urlQueryParams, ...queryParams };

  const queryParts = [];
  Object.keys(allQueryParams).forEach((name) => {
    const encodedName = useHash ? encodeHashParam(name) : encodeQueryParam(name);
    const value = allQueryParams[name];
    if (value === null) {
      queryParts.push(encodedName);
    } else if (value !== undefined) {
      const encodedValue = useHash ? encodeHashParam(value) : encodeQueryParam(value);
      queryParts.push(`${encodedName}=${encodedValue}`);
    }
  });
  const query = queryParts.length ? '?' + queryParts.join('&') : '';

  let urlDelta: UrlParams | Record<string, string>;
  if (useHash) {
    const [hashInit] = urlDetails.hash.substring(1).split('?');
    urlDelta = { hash: `${hashInit || query ? '#' : ''}${hashInit}${query}` };
  } else {
    urlDelta = { search: query };
  }

  const resultUrl = renderUrlParams({ ...urlDetails, ...urlDelta });
  return resultUrl;
}

/**
 * @hidden
 */
export function encodeQueryParam(
  value: string,
): string {
  return encodeURIComponent(value);
}

/**
 * @hidden
 */
 export function decodeQueryParam(
  value: string,
): string {
  return decodeURIComponent(value.replace(URL_PLUS_REGEXP, ' '));
}

/**
 * @hidden
 */
export function encodeHashParam(
  value: string,
): string {
  return encodeURI(value);
}

/**
 * @hidden
 */
export function decodeHashParam(
  value: string,
): string {
  return decodeURI(value.replace(URL_PLUS_REGEXP, ' '));
}
