import { evalNewRandomKey } from '@navify-platform/random';
import { ExtError } from '@navify-platform/error';

const POST_MESSAGE_EVENT_TYPE_MESSAGE = 'message';
const POST_MESSAGE_REQUEST_KEY_LENGTH = 16;
const POST_MESSAGE_REQUEST_TIMEOUT_DEFAULT = 60000; // 1 minute
const POST_MESSAGE_TYPE_REQUEST = 'postMessageRequest';
const POST_MESSAGE_TYPE_RESPONSE = 'postMessageResponse';
const POST_MESSAGE_TYPE_FAILURE = 'postMessageFailure';

/**
 * @hidden
 */
export function windowPost(
  type: string,
  data: any,
  origin: string,
  target: Window,
  extras?: { ports: MessagePort[] },
): void {
  const message = { type, data };
  const ports = extras && extras.ports || [];
  target.postMessage(message, origin || '*', [...ports]);
}

/**
 * @hidden
 */
export type PostConnectHandler = (
  type: string,
  data: any,
  origin: string,
  source: Window,
  extras: { ports: MessagePort[] },
) => Promise<void>;

/**
 * @hidden
 */
export function windowPostConnect(
  handler: PostConnectHandler,
  target: Window,
): () => void {
  const messageHandler = async (event: MessageEvent) => {
    const { data: message, origin, source } = event;
    const type = message && message.type || null;
    const data = message && message.data || null;
    const ports = event.ports && [...event.ports] || null;
    if (!target || target === source) {
      await handler(type, data, origin, <Window>source, { ports });
    }
  };

  window.addEventListener(POST_MESSAGE_EVENT_TYPE_MESSAGE, messageHandler, false);

  const windowPostDisconnect = () => {
    window.removeEventListener(POST_MESSAGE_EVENT_TYPE_MESSAGE, messageHandler, false);
  };
  return windowPostDisconnect;
}

/**
 * @hidden
 */
export type PostRequestResult = {
  response: any,
  error: any,
  origin: string,
  source: Window,
  extras: {
    ports: MessagePort[],
  },
};

/**
 * @hidden
 */
export type PostRequestOptions = {
  timeout?: number,
};

/**
 * @hidden
 */
export async function windowPostRequest(
  request: any,
  target: Window,
  options: PostRequestOptions = {},
): Promise<PostRequestResult> {
  return new Promise<PostRequestResult>((resolve, reject) => {
    const key = evalNewRandomKey(POST_MESSAGE_REQUEST_KEY_LENGTH);

    const allOptions: PostRequestOptions = {
      timeout: POST_MESSAGE_REQUEST_TIMEOUT_DEFAULT,
      ...options,
    };

    const windowPostDisconnect = windowPostConnect(
      async (type, data, origin, source, extras) => {
        if (!data || data.key !== key) {
          return;
        }

        destroy();

        if (type === POST_MESSAGE_TYPE_RESPONSE) {
          const { response } = data;
          resolve({ response, error: null, origin, source, extras });
        } else if (type === POST_MESSAGE_TYPE_FAILURE) {
          const { faults } = data;
          const error = new ExtError(faults);
          reject({ response: null, error, origin, source, extras });
        }
      },
      target,
    );

    const timeout = allOptions.timeout ? setTimeout(
      () => {
        destroy();
        const error = new ExtError({ linkTimeout: true });
        reject({ response: null, error, origin: null, source: null, extras: null });
      },
      allOptions.timeout,
    ) : null;

    const destroy = () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      windowPostDisconnect();
    };

    return windowPost(
      POST_MESSAGE_TYPE_REQUEST,
      { key, request },
      null,
      target,
    );
  });
}

/**
 * @hidden
 */
export type PostServeHandler = (
  request: any,
  origin: string,
  source: Window,
  extras: { ports: MessagePort[] },
) => void;

/**
 * @hidden
 */
export interface PostServer {
  destroy: () => void;
}

/**
 * @hidden
 */
export function windowPostServe(
  requestHandler: PostServeHandler,
  target: Window,
): PostServer {
  const destroy = windowPostConnect(
    async (type, data, origin, source, extras) => {
      if (type !== POST_MESSAGE_TYPE_REQUEST) {
        return;
      }

      const { key, request } = data;

      try {
        const response = await requestHandler(request, origin, source, extras);
        const responseData = { key, response };

        windowPost(
          POST_MESSAGE_TYPE_RESPONSE,
          responseData,
          origin,
          source,
        );
      } catch (error) {
        const faults = error.faults || { error };
        const failureData = { key, faults };

        windowPost(
          POST_MESSAGE_TYPE_FAILURE,
          failureData,
          origin,
          source,
        );
      }
    },
    target,
  );
  return { destroy };
}

/**
 * @hidden
 */
export function parentPost(
  type: string,
  data: any,
  origin: string,
  extras?: { ports: MessagePort[] },
): void {
  const target = window.parent;
  if (!target || target === window) {
    throw new ExtError({ noParent: true });
  }

  return windowPost(type, data, origin || '*', target, extras);
}

/**
 * @hidden
 */
export function parentPostConnect(
  handler: PostConnectHandler,
): () => void {
  const target = window.parent;
  if (!target || target === window) {
    throw new ExtError({ noParent: true });
  }

  return windowPostConnect(handler, target);
}

/**
 * @hidden
 */
export async function parentPostRequest(
  request: any,
  options: PostRequestOptions = {},
): Promise<PostRequestResult> {
  const target = window.parent;
  if (!target || target === window) {
    throw new ExtError({ noParent: true });
  }

  return windowPostRequest(request, target, options);
}

/**
 * @hidden
 */
export function parentPostServe(
  requestHandler: PostServeHandler,
): PostServer {
  const target = window.parent;
  if (!target || target === window) {
    throw new ExtError({ noParent: true });
  }

  return windowPostServe(requestHandler, target);
}
