export function isDefined(...values: any[]): boolean {
  return values.reduce((acc, value) => (acc && value !== undefined), true);
}

export function makeDefined(value: any): any {
  return isDefined(value) ? value : null;
}

export function isSet(...values: any[]): boolean {
  return values.reduce((acc, value) => (acc && value !== undefined && value !== null), true);
}

export const VALUE_STR2BOOL_TRUE_VALUES = ['yes', 'true', 't', 'y', '1'];
export const VALUE_STR2BOOL_FALSE_VALUES = ['no', 'false', 'f', 'n', '0'];

export function str2bool(value: string): boolean {
  if (value === null) {
    return null
  }
  if (typeof value === 'string') {
    const loweredValue = value.toLowerCase();
    if (VALUE_STR2BOOL_TRUE_VALUES.indexOf(loweredValue) !== -1) {
      return true;
    }
    if (VALUE_STR2BOOL_FALSE_VALUES.indexOf(loweredValue) !== -1) {
      return false;
    }
  }
  return !!value;
}
