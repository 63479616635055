import {
  EnvProp,
  EnvParam,
  EnvSource,
  EnvSourceOrigin,
  EnvSourceType,
  EnvValue,
} from './env.types';

export const ENV_DATA_CONST_ENCODED = 'eyJlbnZQbGFjZWhvbGRlciI6dHJ1ZX0='; // atob(JSON.stringify({envPlaceholder: true}))
export const ENV_DATA_FILE_URI = '/navify-env.json';

export const ENV_PROP_SOURCES: Record<EnvProp, EnvSource[]> = {
  [EnvProp.AppAlias]: [
    { origin: EnvSourceOrigin.Seed, key: EnvProp.AppAlias, type: EnvSourceType.String },
    { origin: EnvSourceOrigin.Param, key: EnvParam.AppAlias, type: EnvSourceType.String },
    { origin: EnvSourceOrigin.Default, key: EnvProp.AppAlias, type: EnvSourceType.String },
  ],
  [EnvProp.UrlScheme]: [
    { origin: EnvSourceOrigin.Seed, key: EnvProp.UrlScheme, type: EnvSourceType.String },
    { origin: EnvSourceOrigin.Param, key: EnvParam.UrlScheme, type: EnvSourceType.String },
    { origin: EnvSourceOrigin.Default, key: EnvProp.UrlScheme, type: EnvSourceType.String },
  ],
  [EnvProp.PlatformApiUrl]: [
    { origin: EnvSourceOrigin.Seed, key: EnvProp.PlatformApiUrl, type: EnvSourceType.Url },
    { origin: EnvSourceOrigin.Param, key: EnvParam.PlatformApiUrl, type: EnvSourceType.Url },
    { origin: EnvSourceOrigin.Default, key: EnvProp.PlatformApiUrl, type: EnvSourceType.Url },
  ],
  [EnvProp.PlatformAppAlias]: [
    { origin: EnvSourceOrigin.Seed, key: EnvProp.PlatformAppAlias, type: EnvSourceType.String },
    { origin: EnvSourceOrigin.Param, key: EnvParam.PlatformAppAlias, type: EnvSourceType.String },
    { origin: EnvSourceOrigin.Default, key: EnvProp.PlatformAppAlias, type: EnvSourceType.String },
  ],
  [EnvProp.ProductApiUrl]: [
    { origin: EnvSourceOrigin.Seed, key: EnvProp.ProductApiUrl, type: EnvSourceType.Url },
    { origin: EnvSourceOrigin.Param, key: EnvParam.ProductApiUrl, type: EnvSourceType.Url },
    { origin: EnvSourceOrigin.Default, key: EnvProp.ProductApiUrl, type: EnvSourceType.Url },
  ],
  [EnvProp.AuthType]: [
    { origin: EnvSourceOrigin.Seed, key: EnvProp.AuthType, type: EnvSourceType.String },
    { origin: EnvSourceOrigin.Param, key: EnvParam.AuthType, type: EnvSourceType.String },
    { origin: EnvSourceOrigin.Default, key: EnvProp.AuthType, type: EnvSourceType.String },
  ],
  [EnvProp.AuthUiUrl]: [
    { origin: EnvSourceOrigin.Seed, key: EnvProp.AuthUiUrl, type: EnvSourceType.Url },
    { origin: EnvSourceOrigin.Param, key: EnvParam.AuthUiUrl, type: EnvSourceType.Url },
    { origin: EnvSourceOrigin.Default, key: EnvProp.AuthUiUrl, type: EnvSourceType.Url },
  ],
  [EnvProp.AuthOktaIssuer]: [
    { origin: EnvSourceOrigin.Seed, key: EnvProp.AuthOktaIssuer, type: EnvSourceType.String },
    { origin: EnvSourceOrigin.Param, key: EnvParam.AuthOktaIssuer, type: EnvSourceType.String },
    { origin: EnvSourceOrigin.Default, key: EnvProp.AuthOktaIssuer, type: EnvSourceType.String },
  ],
  [EnvProp.AuthOktaClientId]: [
    { origin: EnvSourceOrigin.Seed, key: EnvProp.AuthOktaClientId, type: EnvSourceType.String },
    { origin: EnvSourceOrigin.Param, key: EnvParam.AuthOktaClientId, type: EnvSourceType.String },
    { origin: EnvSourceOrigin.Default, key: EnvProp.AuthOktaClientId, type: EnvSourceType.String },
  ],
  [EnvProp.AppdEndUserMonitoring]: [
    { origin: EnvSourceOrigin.Seed, key: EnvProp.AppdEndUserMonitoring, type: EnvSourceType.Boolean },
    { origin: EnvSourceOrigin.Param, key: EnvParam.AppdEndUserMonitoring, type: EnvSourceType.Boolean },
    { origin: EnvSourceOrigin.Default, key: EnvProp.AppdEndUserMonitoring, type: EnvSourceType.String },
  ],
  [EnvProp.AppdEndUserMonitoringKey]: [
    { origin: EnvSourceOrigin.Seed, key: EnvProp.AppdEndUserMonitoringKey, type: EnvSourceType.String },
    { origin: EnvSourceOrigin.Param, key: EnvParam.AppdEndUserMonitoringKey, type: EnvSourceType.String },
    { origin: EnvSourceOrigin.Default, key: EnvProp.AppdEndUserMonitoringKey, type: EnvSourceType.String },
  ],
  [EnvProp.CfDistributionId]: [
    { origin: EnvSourceOrigin.Seed, key: EnvProp.CfDistributionId, type: EnvSourceType.String },
    { origin: EnvSourceOrigin.Param, key: EnvParam.CfDistributionId, type: EnvSourceType.String },
    { origin: EnvSourceOrigin.Default, key: EnvProp.CfDistributionId, type: EnvSourceType.String },
  ],
  [EnvProp.TenantAlias]: [
    { origin: EnvSourceOrigin.Seed, key: EnvProp.TenantAlias, type: EnvSourceType.String },
    { origin: EnvSourceOrigin.Param, key: EnvParam.TenantAlias, type: EnvSourceType.String },
    { origin: EnvSourceOrigin.Default, key: EnvProp.TenantAlias, type: EnvSourceType.String },
  ],
};

export const ENV_PROP_DEFAULTS: Partial<Record<EnvProp, EnvValue>> = {
  [EnvProp.UrlScheme]: 'https:',
  [EnvProp.PlatformAppAlias]: 'platform',
  [EnvProp.AppdEndUserMonitoring]: false,
  [EnvProp.AppdEndUserMonitoringKey]: null,
};

export const ENV_CAST_TRUE_REGEX = /(true|t)/i;
export const ENV_CAST_FALSE_REGEX = /(false|f)/i;
