/**
 * Event class.
 */
export class Event {
  protected defaultPreventedValue = false;
  protected propagationStoppedValue = false;

  /**
   * Constructor.
   * @param type Event type.
   * @param data Event data.
   * @param resultValue Event result initial value.
   */
  constructor(
    public readonly type: string,
    public readonly data: any = null,
    protected resultValue: any = null,
  ) { }

  /**
   * Gets the default handler prevent flag value.
   */
  get defaultPrevented(): boolean {
    return this.defaultPreventedValue;
  }

  /**
   * Sets the default handler prevent flag value.
   */
  public preventDefault() {
    this.defaultPreventedValue = true;
  }

  /**
   * Gets the propagation stop flag value.
   */
  get propagationStopped(): boolean {
    return this.propagationStoppedValue;
  }

  /**
   * Sets the propagation stop flag value.
   */
  public stopPropagation() {
    this.propagationStoppedValue = true;
  }

  /**
   * Gets the event result value.
   */
  get result(): any {
    return this.resultValue;
  }

  /**
   * Sets the event result value.
   */
  public setResult(resultValue: any) {
    this.resultValue = resultValue;
  }
}
