import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";

export default function CreateCluster(props) {
  const [clusterCreated, setClusterCreated] = useState(false);
  const navigate = useNavigate();

  function createClusterSubmit(e) {
    const form = e.target;
    const formData = new FormData(form);

    var created = createCluster(e.target.name.value, e.target.tenant_id.value, e.target.oidc_issuer.value);
    e.preventDefault();
  }

  async function createCluster(name, tenant_id, k8s_oidc_issuer) {
    return axios
      .post(
        `https://api.clusters.npsandbox01.sandbox.rginger.com/api/v1/clusters`,
        {
          name: name,
          tenant_id: tenant_id,
          k8s_oidc_issuer: k8s_oidc_issuer,
        },
        {
          headers: {
            "X-Navify-Tenant": "navify-core-administrators", // TODO: get from redux
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        setClusterCreated(true);
        navigate(`/clusters/${res.data.cluster_id}`);
      });
  }

  return (
    <div>
      <h1>Create Cluster</h1>
      <Link to="/">Back</Link>
      <form onSubmit={createClusterSubmit}>
        <dl>
          <dt>
            <label for="name">Name</label>
          </dt>
          <dd>
            <input type="text" name="name" />
          </dd>
          <dt>
            <label for="tenant_id">tenant id</label>
          </dt>
          <dd>
            <input type="text" name="tenant_id" />
          </dd>
          <dt>
            <label for="oidc_issuer">oidc issuer for k8s</label>
          </dt>
          <dd>
            <input type="text" name="oidc_issuer" />
          </dd>
        </dl>
        <button type="submit">CREATE</button>
      </form>
    </div>
  );
}
