import { ExtError } from '@navify-platform/error';

const RANDOM_KEYS = {};
const RANDOM_KEYS_ITERATION_LIMIT = 1000;

/**
 * @hidden
 */
export function evalRandomKey(length: number): string {
  if (length <= 0) {
    throw new ExtError({ randomError: true, lengthInvalid: true });
  }

  let key = '';
  while (key.length < length) {
    key += Math.random().toString(36).substr(2);
  }
  key = key.substring(0, length);
  return key;
}

/**
 * @hidden
 */
export function evalNewRandomKey(length: number): string {
  if (length <= 0) {
    throw new ExtError({ randomError: true, lengthInvalid: true });
  }

  let key: string;
  let iteration = 0;
  do {
    key = evalRandomKey(length);
    iteration += 1;
  } while (RANDOM_KEYS[key] && iteration <= RANDOM_KEYS_ITERATION_LIMIT);
  RANDOM_KEYS[key] = true;
  return key;
}
