import { Env } from '@navify-platform/env';
import {
  getWindowUrl,
  setWindowUrl,
  renderUrlQueryParams,
  getUrlQueryParam,
} from '@navify-platform/url';
import { ExtError } from '@navify-platform/error';
import { Event } from '@navify-platform/event';
import {
  IframeLink,
  IframeLinkRequestOptions,
} from '@navify-platform/iframe-link';

import {
  AuthEnv,
  AuthOptions,
  AuthConf,
  AuthSession,
  AuthQueryParam,
  AuthEventType,
  AuthRequestReturnBy,
  AuthRedirectionType,
  AuthLoginArgs,
  AuthLoginRedirectArgs,
  AuthLoginIframeArgs,
  AuthLoginReturn,
  AuthLoginRequest,
  AuthLoginResponse,
  AuthLoginAction,
  AuthLogoutArgs,
  AuthLogoutRedirectArgs,
  AuthLogoutReturn,
  AuthLogoutRequest,
  AuthLogoutResponse,
} from './auth.types';
import {
  AUTH_OPTIONS_DEFAULTS,
  AUTH_LINK_PARENT_REQUEST_OPTIONS_DEFAULTS,
  AUTH_LINK_WINDOWS_REQUEST_OPTIONS_DEFAULTS,
} from './auth.consts';
import {
  validateAuthSession,
  evalAuthSessionExpiration,
  immutablyEvalAuthSession,
} from './auth-session';
import { AuthHttpClient } from './auth-http-client';
import {
  makeDefined,
  isSet,
  str2bool,
} from './auth-value';
import { replaceWindowHistoryState } from './auth-history';
import {
  AuthTimer,
  createTimer,
} from './auth-timer';
import { decodeQuery } from './auth-query';

const AUTH_LINK_PARENT_REQUEST_TYPE_GET_SESSION = 'parent:getSession';
const AUTH_LINK_PARENT_REQUEST_TYPE_LOGIN = 'parent:login';
const AUTH_LINK_PARENT_REQUEST_TYPE_GET_LOGIN_RETURN = 'parent:getLoginReturn';
const AUTH_LINK_PARENT_REQUEST_TYPE_REFRESH_SESSION = 'parent:refreshSession';
const AUTH_LINK_PARENT_REQUEST_TYPE_LOGOUT = 'parent:logout';
const AUTH_LINK_PARENT_REQUEST_TYPE_GET_LOGOUT_RETURN = 'parent:getLogoutReturn';
const AUTH_LINK_WINDOW_REQUEST_EVENT_SESSION_CHANGE = 'window:sessionChange';
const AUTH_LINK_WINDOW_REQUEST_EVENT_BEFORE_LOGIN = 'window:eventBeforeLogin';
const AUTH_LINK_WINDOW_REQUEST_EVENT_AFTER_LOGIN = 'window:eventAfterLogin';
const AUTH_LINK_WINDOW_REQUEST_EVENT_BEFORE_LOGOUT = 'window:eventBeforeLogout';
const AUTH_LINK_WINDOW_REQUEST_EVENT_AFTER_LOGOUT = 'window:eventAfterLogout';

/**
 * Authentication class.
 */
export class Auth {
  protected authOptionsInput: AuthOptions;
  protected authOptions: AuthOptions;
  protected authEnv: AuthEnv;

  protected authHttpClient: AuthHttpClient = null;
  protected authSession: AuthSession = null;
  protected loginResponse: AuthLoginResponse = null;
  protected logoutResponse: AuthLogoutResponse = null;
  protected eventHandlers: ((event: Event) => void)[] = [];

  protected beforeExpirationTimer: AuthTimer = null;
  protected expirationTimer: AuthTimer = null;
  protected pollingTimer: AuthTimer = null;

  protected authLink: IframeLink = null;

  constructor(
    protected authConfInput: AuthConf = {},
  ) { }

  /**
   * Creates and initializes an instance.
   * @returns Promise resolved when the initialization is done.
   */
   static async init(
    authConfInput?: AuthConf,
  ): Promise<Auth> {
    const auth = new Auth(authConfInput);
    await auth.init();
    return auth;
  }

  /**
   * Initializes the instance.
   * @returns Promise resolved when the initialization is done.
   */
  async init() {
    await this.initConf();
    await this.initHttpClient();
    await this.initLink();
  }

  /**
   * Destroys the instance.
   * @returns Promise resolved when the destruction is done.
   */
  async destroy() {
    await this.authLink.destroy();
  }

  protected async initConf() {
    this.authOptionsInput = <AuthConf>{
      ...(this.authConfInput || {}),
      env: undefined,
    }

    this.authOptions = {
      ...AUTH_OPTIONS_DEFAULTS,
      ...this.authOptionsInput,
    };

    const env = this.authConfInput.env || new Env();
    const platformApiUrl = await env.getPlatformApiUrl();
    const authUiUrl = await env.getAuthUiUrl();
    const appAlias = await env.getAppAlias();
    const tenantAlias = await env.getTenantAlias();
    if (!isSet(platformApiUrl, authUiUrl, appAlias, tenantAlias)) {
      throw new ExtError({ envInvalid: true });
    }

    this.authEnv = { platformApiUrl, authUiUrl, appAlias, tenantAlias };
  }

  protected async initHttpClient() {
    this.authHttpClient = new AuthHttpClient(this.authEnv);
  }

  protected async initLink() {
    this.authLink = new IframeLink(
      async (request, origin, source) => {
        return await this.linkParentHandle(request.type, request.data, origin, source);
      },
      async (request, origin, source) => {
        return await this.linkWindowHandle(request.type, request.data, origin, source);
      },
    );

    await this.authLink.init();
  }

  /**
   * Requests and returns the session data.
   * @returns Promised resolved with the session object.
   */
  async getSession(): Promise<AuthSession> {
    try {
      const session = this.authLink.hasParent() ?
        await this.getSessionParentRequest() :
        await this.getSessionHandle();

      await this.applySession(session);
      return this.authSession;
    } catch (error) {
      throw new ExtError({}, error);
    }
  }

  protected async getSessionHandle(): Promise<AuthSession> {
    return this.authHttpClient.getSession();
  }

  protected async getSessionParentRequest(): Promise<AuthSession> {
    const { response } = await this.linkParentRequest(
      AUTH_LINK_PARENT_REQUEST_TYPE_GET_SESSION,
    );
    return response;
  }

  protected async getSessionParentHandle(
    origin: string,
    source: Window,
  ): Promise<AuthSession> {
    return this.getSession();
  }

  protected async getSessionSilent(): Promise<AuthSession> {
    try {
      return this.getSession();
    } catch (error) {
      return null;
    }
  }

  protected async login(
    args: AuthLoginArgs,
  ): Promise<AuthLoginReturn> {
    try {
      const loginRequest: AuthLoginRequest = {
        env: this.authEnv,
        options: this.authOptionsInput,
        returnBy: makeDefined(args?.returnBy),
        returnTo: makeDefined(args?.returnTo) || getWindowUrl(),
        username: makeDefined(args?.username),
        stateMap: { ['']: makeDefined(args?.state) },
        reason: makeDefined(args?.reason),
      };
      await this.loginRequest(loginRequest);
      return this.getLoginReturn();
    } catch (error) {
      throw new ExtError({}, error);
    }
  }

  /**
   * Initiates the redirect type logout float.
   * @param AuthLogoutRedirectArgs Logout redirect arguments object.
   * @returns Promise resolved with the login flow is initiated.
   */
  async loginRedirect(
    args?: AuthLoginRedirectArgs,
  ): Promise<AuthLoginReturn> {
    return this.login({
      returnBy: AuthRequestReturnBy.redirect,
      returnTo: args?.returnTo || getWindowUrl(),
      username: args?.username,
      state: args?.state,
      reason: args?.reason,
    });
  }

  /**
   * Loads the login UI application into an iframe.
   * @param AuthLoginIframeArgs Login iframe arguments object.
   * @returns Promise resolved with the login return object.
   */
  async loginIframe(
    args?: AuthLoginIframeArgs,
  ): Promise<AuthLoginReturn> {
    return this.login({
      returnBy: AuthRequestReturnBy.iframe,
      username: args?.username,
      state: args?.state,
      reason: args?.reason,
    });
  }

  protected async loginRequest(
    loginRequest: AuthLoginRequest,
  ): Promise<AuthLoginResponse> {
    if (this.authLink.hasParent()) {
      return this.loginParentRequest(loginRequest);
    }

    return this.loginHandle(loginRequest);
  }

  protected async loginHandle(
    loginRequest: AuthLoginRequest,
  ): Promise<AuthLoginResponse> {
    await this.trigger(AuthEventType.beforeLogin);
    await this.linkWindowsRequest(AUTH_LINK_WINDOW_REQUEST_EVENT_BEFORE_LOGIN);

    if (loginRequest.returnBy === AuthRequestReturnBy.redirect) {
      const authreqId = await this.authHttpClient.appSetAuthStorage(loginRequest);
      if (!authreqId) {
        throw new ExtError({ authreqIdInvalid: true });
      }

      await this.applyLoginResponse(null);
      await this.authHttpClient.appLoginRedirect(authreqId);
    } else if (loginRequest.returnBy === AuthRequestReturnBy.iframe) {
      const loginResponse = await this.authHttpClient.appLoginIframePopup(loginRequest);
      await this.applyLoginResponse(loginResponse);
    } else {
      throw new ExtError({ returnByInvalid: true });
    }

    await this.linkWindowsRequest(AUTH_LINK_WINDOW_REQUEST_EVENT_AFTER_LOGIN);
    await this.trigger(AuthEventType.afterLogin);

    return this.loginResponse;
  }

  protected async loginParentRequest(
    loginRequest: AuthLoginRequest,
  ): Promise<AuthLoginResponse> {
    const { response } = await this.linkParentRequest(
      AUTH_LINK_PARENT_REQUEST_TYPE_LOGIN,
      loginRequest,
      { timeout: null },
    );
    return response;
  }

  protected async loginParentHandle(
    loginRequest: AuthLoginRequest,
    origin: string,
    source: Window,
  ): Promise<AuthLoginResponse> {
    const beforeLoginEvent = await this.trigger(
      AuthEventType.beforeWindowLogin,
      null,
      {
        returnBy: loginRequest.returnBy,
        returnTo: loginRequest.returnTo ? getWindowUrl() : null,
        username: loginRequest.username,
        state: null,
        reason: loginRequest.reason,
      },
    );
    const { returnBy, returnTo, username, state, reason } = beforeLoginEvent.result;

    const response = await this.loginRequest({
      env: this.authEnv,
      options: this.authOptionsInput,
      returnBy,
      returnTo,
      username,
      stateMap: {
        ['']: state,
        [origin]: loginRequest.stateMap[''],
      },
      reason,
    });

    await this.trigger(AuthEventType.afterWindowLogin);

    return response;
  }

  /**
   * Requests and returns login response data.
   * @returns Promise resolved with the login response data.
   */
  async getLoginReturn(): Promise<AuthLoginReturn> {
    try {
      const loginResponse = await this.getLoginReturnRequest();
      if (!loginResponse) {
        return null;
      }

      const responseStateMap = loginResponse.stateMap || {};
      return {
        success: loginResponse.success || null,
        state: responseStateMap[''] || null,
      };
    } catch (error) {
      throw new ExtError({}, error);
    }
  }

  protected async getLoginReturnRequest(): Promise<AuthLoginResponse> {
    if (this.authLink.hasParent()) {
      return this.getLoginReturnParentRequest();
    }

    return this.getLoginReturnHandle();
  }

  protected async getLoginReturnHandle(): Promise<AuthLoginResponse> {
    const url = getWindowUrl();
    const authresId = getUrlQueryParam(
      url,
      AuthQueryParam.response,
      this.authOptions.redirectUrlUseHash,
    );

    if (authresId) {
      const loginResponse: AuthLoginResponse = await this.authHttpClient.appGetAuthStorage(authresId);

      const replaceUrl = renderUrlQueryParams(
        url,
        { [AuthQueryParam.response]: undefined },
        this.authOptions.redirectUrlUseHash,
      );
      replaceWindowHistoryState(document.title, replaceUrl);

      await this.applyLoginResponse(loginResponse);
    }

    return this.loginResponse;
  }

  protected async getLoginReturnParentRequest(): Promise<AuthLoginResponse> {
    const { response } = await this.linkParentRequest(
      AUTH_LINK_PARENT_REQUEST_TYPE_GET_LOGIN_RETURN,
    );
    return response;
  }

  protected async getLoginReturnParentHandle(
    origin: string,
    source: Window,
  ): Promise<AuthLoginResponse> {
    const loginResponse = await this.getLoginReturnRequest();
    if (!loginResponse) {
      return null;
    }

    const responseStateMap = loginResponse.stateMap || {};
    return {
      ...loginResponse,
      stateMap: { ['']: responseStateMap[origin] || null },
    };
  }

  /**
   * Refreshes session.
   * @returns Promise resolved with new session object.
   */
  async refreshSession(): Promise<AuthSession> {
    try {
      if (this.authLink.hasParent()) {
        return this.refreshSessionParentRequest();
      }

      const session = await this.refreshSessionHandle();
      await this.applySession(session);
      return this.authSession;
    } catch (error) {
      throw new ExtError({}, error);
    }
  }

  protected async refreshSessionHandle(): Promise<AuthSession> {
    return this.authHttpClient.refreshSession();
  }

  protected async refreshSessionParentRequest(): Promise<AuthSession> {
    const { response } = await this.linkParentRequest(
      AUTH_LINK_PARENT_REQUEST_TYPE_REFRESH_SESSION,
    );
    return response;
  }

  protected async refreshSessionParentHandle(
    origin: string,
    source: Window,
  ): Promise<AuthSession> {
    return this.refreshSession();
  }

  protected async logout(
    args: AuthLogoutArgs,
  ): Promise<AuthLogoutReturn> {
    try {
      const logoutRequest: AuthLogoutRequest = {
        env: this.authEnv,
        options: this.authOptionsInput,
        returnBy: makeDefined(args?.returnBy),
        returnTo: makeDefined(args?.returnTo) || getWindowUrl(),
        stateMap: { ['']: makeDefined(args?.state) },
        reason: makeDefined(args?.reason),
      };

      await this.applySession(null);
      await this.applyLoginResponse(null);

      await this.logoutRequest(logoutRequest);
      return this.getLogoutReturn();
    } catch (error) {
      throw new ExtError({}, error);
    }
  }

  /**
   * Initiates the redirect type logout float.
   * @param AuthLogoutRedirectArgs Logout redirect arguments object.
   * @returns Promise resolved with the logout flow is initiated.
   */
   async logoutRedirect(
    args?: AuthLogoutRedirectArgs,
  ): Promise<AuthLogoutReturn> {
    return this.logout({
      returnBy: AuthRequestReturnBy.redirect,
      returnTo: args?.returnTo || getWindowUrl(),
      state: args?.state,
      reason: args?.reason,
      logMessage: args?.logMessage,
    });
  }

  protected async logoutRequest(
    logoutRequest: AuthLogoutRequest,
  ): Promise<AuthLogoutResponse> {
    if (this.authLink.hasParent()) {
      return this.logoutParentRequest(logoutRequest);
    }

    return this.logoutHandle(logoutRequest);
  }

  protected async logoutHandle(
    logoutRequest: AuthLogoutRequest,
  ): Promise<AuthLogoutResponse> {
    await this.trigger(AuthEventType.beforeLogout);
    await this.linkWindowsRequest(AUTH_LINK_WINDOW_REQUEST_EVENT_BEFORE_LOGOUT);

    if (logoutRequest.returnBy === AuthRequestReturnBy.redirect) {
      const authreqId = await this.authHttpClient.appSetAuthStorage(logoutRequest);

      this.applyLogoutResponse(null);

      const { returnBy, returnTo, reason, logMessage } = logoutRequest;
      await this.authHttpClient.appLogoutRedirect(
        authreqId,
        returnBy,
        returnTo,
        reason,
        logMessage,
        this.authOptions.redirectUrlUseHash);
    }

    await this.trigger(AuthEventType.afterLogout);
    await this.linkWindowsRequest(AUTH_LINK_WINDOW_REQUEST_EVENT_AFTER_LOGOUT);

    return this.logoutResponse;
  }

  protected async logoutParentRequest(
    logoutRequest: AuthLogoutRequest,
  ): Promise<AuthLogoutResponse> {
    const { response } = await this.linkParentRequest(
      AUTH_LINK_PARENT_REQUEST_TYPE_LOGOUT,
      logoutRequest,
    );
    return response;
  }

  protected async logoutParentHandle(
    logoutRequest: AuthLogoutRequest,
    origin: string,
    source: Window,
  ): Promise<AuthLogoutResponse> {
    const beforeLogoutEvent = await this.trigger(
      AuthEventType.beforeWindowLogout,
      null,
      {
        returnBy: logoutRequest.returnBy,
        returnTo: logoutRequest.returnTo ? getWindowUrl() : null,
        state: null,
        reason: logoutRequest.reason,
      },
    );
    const { returnBy, returnTo, state, reason } = beforeLogoutEvent.result;

    const response = await this.logoutRequest({
      env: this.authEnv,
      options: this.authOptionsInput,
      returnBy,
      returnTo,
      stateMap: {
        ['']: state,
        [origin]: logoutRequest.stateMap[''],
      },
      reason,
    });

    await this.trigger(AuthEventType.afterWindowLogout);

    return response;
  }

  /**
   * Requests and returns logout response data.
   * @returns Promise resolved with the logout response data.
   */
   async getLogoutReturn(): Promise<AuthLogoutReturn> {
    try {
      const loginResponse = await this.getLogoutReturnRequest();
      if (!loginResponse) {
        return null;
      }

      const responseStateMap = loginResponse.stateMap || {};
      return {
        success: loginResponse.success || null,
        state: responseStateMap[''] || null,
      };
    } catch (error) {
      throw new ExtError({}, error);
    }
  }

  protected async getLogoutReturnRequest(): Promise<AuthLogoutResponse> {
    if (this.authLink.hasParent()) {
      return this.getLogoutReturnParentRequest();
    }

    return this.getLogoutReturnHandle();
  }

  protected async getLogoutReturnHandle(): Promise<AuthLogoutResponse> {
    const url = getWindowUrl();
    const authred = getUrlQueryParam(
      url,
      AuthQueryParam.redirection,
      this.authOptions.redirectUrlUseHash,
    );

    if (authred) {
      const redirection = decodeQuery(authred);

      if (redirection.type === AuthRedirectionType.logout) {
        const { authreqId } = redirection;
        const logoutRequest = await this.authHttpClient.appGetAuthStorage(authreqId);
        const { stateMap } = logoutRequest;

        const success = str2bool(getUrlQueryParam(
          url,
          AuthQueryParam.success,
          this.authOptions.redirectUrlUseHash,
        )) === false ? false : true;

        const logoutResponse: AuthLogoutResponse = {
          success,
          stateMap,
        };

        const replaceUrl = renderUrlQueryParams(
          url,
          { [AuthQueryParam.redirection]: undefined, [AuthQueryParam.success]: undefined },
          this.authOptions.redirectUrlUseHash,
        );
        replaceWindowHistoryState(document.title, replaceUrl);

        await this.applyLogoutResponse(logoutResponse);
      }
    }

    return this.logoutResponse;
  }

  protected async getLogoutReturnParentRequest(): Promise<AuthLogoutResponse> {
    const { response } = await this.linkParentRequest(
      AUTH_LINK_PARENT_REQUEST_TYPE_GET_LOGOUT_RETURN,
    );
    return response;
  }

  protected async getLogoutReturnParentHandle(
    origin: string,
    source: Window,
  ): Promise<AuthLogoutResponse> {
    const loginResponse = await this.getLogoutReturnRequest();
    if (!loginResponse) {
      return null;
    }

    const responseStateMap = loginResponse.stateMap || {};
    return {
      ...loginResponse,
      stateMap: { ['']: responseStateMap[origin] || null },
    };
  }

  protected async sessionChangeWindowHandle(
    authSession: AuthSession,
    origin: string,
    source: Window,
  ): Promise<void> {
    await this.applySession(authSession);
    await this.linkWindowsRequest(AUTH_LINK_WINDOW_REQUEST_EVENT_SESSION_CHANGE, authSession);
  }

  protected async beforeParentLoginWindowHandle(
    origin: string,
    source: Window,
  ): Promise<void> {
    await this.trigger(AuthEventType.beforeParentLogin);
    await this.linkWindowsRequest(AUTH_LINK_WINDOW_REQUEST_EVENT_BEFORE_LOGIN);
  }

  protected async afterParentLoginWindowHandle(
    origin: string,
    source: Window,
  ): Promise<void> {
    await this.trigger(AuthEventType.afterParentLogin);
    await this.linkWindowsRequest(AUTH_LINK_WINDOW_REQUEST_EVENT_AFTER_LOGIN);
  }

  protected async beforeParentLogoutWindowHandle(
    origin: string,
    source: Window,
  ): Promise<void> {
    await this.trigger(AuthEventType.beforeParentLogout);
    await this.linkWindowsRequest(AUTH_LINK_WINDOW_REQUEST_EVENT_BEFORE_LOGOUT);
  }

  protected async afterParentLogoutWindowHandle(
    origin: string,
    source: Window,
  ): Promise<void> {
    await this.trigger(AuthEventType.afterParentLogout);
    await this.linkWindowsRequest(AUTH_LINK_WINDOW_REQUEST_EVENT_AFTER_LOGOUT);
  }

  protected async applySession(
    authSession: AuthSession,
  ): Promise<AuthSession> {
    const authSessionValidated = validateAuthSession(authSession, this.authOptions);
    const authSessionNew = immutablyEvalAuthSession(authSessionValidated, this.authSession);

    if (this.authSession !== authSessionNew) {
      this.resetSessionExpiration(authSessionNew);
      this.resetSessionPolling();

      await this.linkWindowsRequest(AUTH_LINK_WINDOW_REQUEST_EVENT_SESSION_CHANGE, authSessionNew);
    }

    if (authSessionNew) {
      if (!this.authSession) {
        await this.trigger(AuthEventType.sessionStart);
      }

      const { beforeExpiresIn } = evalAuthSessionExpiration(authSessionNew, this.authOptions);
      if (beforeExpiresIn <= 0) {
        if (this.authOptions.sessionAutoRefresh) {
          try {
            await this.refreshSession();
          } catch (error) {
            await this.trigger(AuthEventType.beforeSessionExpiration);
          }
        } else {
          await this.trigger(AuthEventType.beforeSessionExpiration);
        }
      }
    } else if (this.authSession) {
      const { expiresIn } = evalAuthSessionExpiration(this.authSession, this.authOptions);
      if (expiresIn <= 0) {
        await this.trigger(AuthEventType.sessionExpiration);
      } else {
        await this.trigger(AuthEventType.sessionEnd);
      }
    }

    return this.authSession = authSessionNew;
  }

  protected resetSessionExpiration(
    authSession: AuthSession,
  ) {
    if (this.beforeExpirationTimer) {
      this.beforeExpirationTimer.destroy();
      this.beforeExpirationTimer = null;
    }
    if (this.expirationTimer) {
      this.expirationTimer.destroy();
      this.expirationTimer = null;
    }

    if (!authSession) {
      return;
    }

    const { beforeExpiresIn, expiresIn } = evalAuthSessionExpiration(authSession, this.authOptions);

    if (beforeExpiresIn > 0) {
      this.beforeExpirationTimer = createTimer(
        () => this.getSessionSilent(),
        beforeExpiresIn * 1000,
      );
    }
    if (expiresIn > 0) {
      this.expirationTimer = createTimer(
        () => this.getSessionSilent(),
        expiresIn * 1000,
      );
    }
  }

  protected resetSessionPolling() {
    if (this.pollingTimer) {
      this.pollingTimer.destroy();
      this.pollingTimer = null;
    }

    const sessionPollingIntervalTime = this.authOptions.sessionPollingIntervalTime;

    if (sessionPollingIntervalTime) {
      this.pollingTimer = createTimer(
        () => this.getSessionSilent(),
        sessionPollingIntervalTime * 1000,
        true,
      );
    }
  }

  protected async applyLoginResponse(loginResponse: AuthLoginResponse) {
    if (loginResponse && loginResponse.action) {
      await this.applyLoginAction(loginResponse.action);
    }

    this.loginResponse = loginResponse;
  }

  protected async applyLoginAction(loginAction: AuthLoginAction) {
    if (loginAction.type === 'REDIRECT') {
      const { url } = loginAction;
      setWindowUrl(url);
    } else {
      throw new ExtError({ actionInvalid: true });
    }
  }

  protected async applyLogoutResponse(logoutResponse: AuthLogoutResponse) {
    this.logoutResponse = logoutResponse;
  }

  /**
   * Registers authentication events handler.
   * @param eventHandler Events handler function.
   * @returns Ubsubscribe function.
   */
  subscribe(
    eventHandler: (event: Event) => void,
  ): () => void {
    this.eventHandlers.push(eventHandler);
    const unsubscribe = () => {
      this.eventHandlers.splice(this.eventHandlers.indexOf(eventHandler), 1);
    };
    return unsubscribe;
  }

  protected async trigger(
    type: string,
    data: any = null,
    resultValue: any = null,
  ): Promise<Event> {
    const event = new Event(type, data, resultValue);
    const eventRun = async (
      eventHandlers: ((event: any) => void)[],
      event: Event,
    ): Promise<Event> => {
      const [eventHandler, ...eventHandlersRest] = eventHandlers;
      if (eventHandler) {
        await eventHandler(event);
        return await eventRun(eventHandlersRest, event);
      }
      return event;
    };

    return await eventRun(this.eventHandlers, event);
  }

  protected async linkParentRequest(
    type: string,
    data: any = null,
    options: IframeLinkRequestOptions = {},
  ): Promise<{
    response: any,
    origin: string,
  }> {
    const request = { type, data };
    const result = await this.authLink.parentRequest(
      request,
      { ...AUTH_LINK_PARENT_REQUEST_OPTIONS_DEFAULTS, ...options },
    );
    const { response, origin } = result;
    return { response, origin };
  }

  protected async linkParentHandle(
    type: string,
    data: any,
    origin: string,
    source: Window,
  ): Promise<any> {
    if (type === AUTH_LINK_PARENT_REQUEST_TYPE_GET_SESSION) {
      return await this.getSessionParentHandle(origin, source);
    }
    if (type === AUTH_LINK_PARENT_REQUEST_TYPE_LOGIN) {
      const loginRequest: AuthLoginRequest = data;
      return await this.loginParentHandle(loginRequest, origin, source);
    }
    if (type === AUTH_LINK_PARENT_REQUEST_TYPE_GET_LOGIN_RETURN) {
      return await this.getLoginReturnParentHandle(origin, source);
    }
    if (type === AUTH_LINK_PARENT_REQUEST_TYPE_REFRESH_SESSION) {
      return await this.refreshSessionParentHandle(origin, source);
    }
    if (type === AUTH_LINK_PARENT_REQUEST_TYPE_LOGOUT) {
      const logoutRequest: AuthLogoutRequest = data;
      return await this.logoutParentHandle(logoutRequest, origin, source);
    }
    if (type === AUTH_LINK_PARENT_REQUEST_TYPE_GET_LOGOUT_RETURN) {
      return await this.getLogoutReturnParentHandle(origin, source);
    }
  }

  protected async linkWindowsRequest(
    type: string,
    data: any = null,
    options: IframeLinkRequestOptions = {},
  ): Promise<{
    response: any,
    error: any,
  }[]> {
    const request = { type, data };
    const results = await this.authLink.windowsRequest(
      request,
      { ...AUTH_LINK_WINDOWS_REQUEST_OPTIONS_DEFAULTS, ...options },
    );
    return results;
  }

  protected async linkWindowHandle(
    type: string,
    data: any,
    origin: string,
    source: Window,
  ): Promise<any> {
    if (type === AUTH_LINK_WINDOW_REQUEST_EVENT_SESSION_CHANGE) {
      const authSession: AuthSession = data;
      return await this.sessionChangeWindowHandle(authSession, origin, source);
    }
    if (type === AUTH_LINK_WINDOW_REQUEST_EVENT_BEFORE_LOGIN) {
      return await this.beforeParentLoginWindowHandle(origin, source);
    }
    if (type === AUTH_LINK_WINDOW_REQUEST_EVENT_AFTER_LOGIN) {
      return await this.afterParentLoginWindowHandle(origin, source);
    }
    if (type === AUTH_LINK_WINDOW_REQUEST_EVENT_BEFORE_LOGOUT) {
      return await this.beforeParentLogoutWindowHandle(origin, source);
    }
    if (type === AUTH_LINK_WINDOW_REQUEST_EVENT_AFTER_LOGOUT) {
      return await this.afterParentLogoutWindowHandle(origin, source);
    }
  }
}
