import {
  renderUrlQueryParams,
  setWindowUrl,
} from '@navify-platform/url';
import { ExtError } from '@navify-platform/error';
import { getRequest } from '@navify-platform/http';
import {
  iframeRequest,
  iframePopup,
} from '@navify-platform/iframe-request';

import {
  AuthEnv,
  AuthSession,
  AuthQueryParam,
  AuthRequestReturnBy,
  AuthRedirectionType,
  AuthLoginRequest,
  AuthLoginResponse,
  AuthLogoutReason,
} from './auth.types';
import {
  AUTH_LOGOUT_REASON_LOG_MESSAGES,
} from './auth.consts';
import { encodeQuery } from './auth-query';

const GET_REQUEST_OPTIONS = {
  retryIf: async (error) => error?.faults?.httpServerError,
  retryAfter: 1000, // 1 second
  retryTimes: 2,
};

/**
 * @hidden
 */
export class AuthHttpClient {
  constructor(
    protected authEnv: AuthEnv,
  ) { }

  async getSession(): Promise<AuthSession> {
    try {
      const url = `${this.authEnv.platformApiUrl}/api/v1/auth/session-info`;
      const response = await getRequest(url, { ...GET_REQUEST_OPTIONS });
      const session = {
        expiresAt: response.expires,
        profile: {
          username: response.profile.preferred_username,
          displayName: response.profile.name,
        },
      };
      return session;
    } catch (error) {
      if (error.faults && !!error.faults.httpUnauthorized) {
        return null;
      }
      throw new ExtError({ getSessionError: true }, error);
    }
  }

  async refreshSession(): Promise<AuthSession> {
    try {
      const url = `${this.authEnv.platformApiUrl}/api/v1/auth/refresh`;
      await getRequest(url, { ...GET_REQUEST_OPTIONS });
      const session = await this.getSession();
      return session;
    } catch (error) {
      throw new ExtError(
        {
          refreshSessionError: true,
          noSession: error.faults && !!error.faults.httpUnauthorized,
        },
        error,
      );
    }
  }

  async appSetAuthStorage(
    value: any,
    key?: string,
  ): Promise<string> {
    const response = await iframeRequest(`${this.authEnv.authUiUrl}/storage/set.html`, { key, value });
    return response.key;
  }

  async appGetAuthStorage(
    key: string,
  ): Promise<any> {
    const response  = await iframeRequest(`${this.authEnv.authUiUrl}/storage/get.html`, { key });
    return response.value;
  }

  async appLoginRedirect(
    authreqId: string,
  ): Promise<void> {
    const appLoginUrl = renderUrlQueryParams(
      `${this.authEnv.authUiUrl}/login`,
      { [AuthQueryParam.request]: authreqId },
    );
    setWindowUrl(appLoginUrl);
  }

  async appLoginIframePopup(
    loginRequest: AuthLoginRequest,
  ): Promise<AuthLoginResponse> {
    const appLoginUrl = `${this.authEnv.authUiUrl}/login`;
    const loginResponse = <AuthLoginResponse>await iframePopup(appLoginUrl, { loginRequest });
    return loginResponse;
  }

  async appLogoutRedirect(
    authreqId: string,
    returnBy: string,
    returnTo: string,
    reason: AuthLogoutReason,
    logMessage: string,
    useHash: boolean,
  ): Promise<void> {
    try {
      const logoutStyle = {
        [AuthRequestReturnBy.redirect]: 'REDIRECT',
        [AuthRequestReturnBy.iframe]: 'IFRAME',
      }[returnBy];

      const authred = encodeQuery({ type: AuthRedirectionType.logout, authreqId });
      const returnToQuery = renderUrlQueryParams(
        returnTo,
        { [AuthQueryParam.redirection]: authred },
        useHash,
      );

      const messagePcs = [];
      if (reason) {
        messagePcs.push(`Reason: ${AUTH_LOGOUT_REASON_LOG_MESSAGES[reason]}`);
      }
      if (logMessage) {
        messagePcs.push(`Log message: ${logMessage}`);
      }
      const message = messagePcs.join('; ') || undefined;

      const logoutUrl = renderUrlQueryParams(
        `${this.authEnv.platformApiUrl}/api/v1/auth/logout`,
        { logout_style: logoutStyle, post_logout_redirect_uri: returnToQuery, message },
      );
      setWindowUrl(logoutUrl);
    } catch (error) {
      throw new ExtError(
        {
          logoutError: true,
          noSession: error.faults && !!error.faults.httpUnauthorized,
        },
        error,
      );
    }
  }
}
