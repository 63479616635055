import { Env } from '@navify-platform/env';

/**
 * Authentication options interface.
 */
 export interface AuthOptions {
  /** How much time before expiration should the library emit the
   *  AuthEventType.beforeSessionExpiration event (seconds). */
  sessionExpirationBeforeTime?: number;
  /** How often should the library send a polling request to check
   *  the existing session (seconds). */
  sessionPollingIntervalTime?: number;
  /** Flag deciding whether to refresh session automatically before session expiration. */
  sessionAutoRefresh?: boolean;
  /** Flag deciding whether to use URL hash instead of query parameters. */
  redirectUrlUseHash?: boolean;
  /** Flag deciding whether to force skip the identification step. */
  identificationFormSkip?: boolean;
  /** Flag deciding whether to submit the identification step if username is provided. */
  identificationFormSubmit?: boolean;
  /** Page title customization HTML. */
  customPageTitle?: string;
  /** Background style customization CSS. */
  customBackgroundStyle?: string;
  /** Header customization HTML. */
  customHeader?: string;
  /** Headline customization HTML. */
  customHeadline?: string;
  /** Caption customization HTML. */
  customCaption?: string;
  /** Footer customization HTML. */
  customFooter?: string;
  /** Internationalization flag. */
  i18n?: boolean;
  /** Internationalized customizations. */
  locale?: Record<string, {
    customPageTitle?: string;
    customBackgroundStyle?: string;
    customHeader?: string;
    customHeadline?: string;
    customCaption?: string;
    customFooter?: string;
  }>;
}

/**
 * Authentication configuration interface.
 */
 export interface AuthConf extends AuthOptions {
  /** Environment (@navify-platform/env) object. */
  env?: Env;
}

/**
 * Authentication environment interface.
 */
 export interface AuthEnv {
  platformApiUrl: string;
  authUiUrl: string;
  appAlias: string;
  tenantAlias: string;
}

/**
 * Authentication session interface.
 */
export interface AuthSession {
  /** When the session expires (milliseconds). */
  expiresAt: number;
  /** Authentication user profile.  */
  profile: AuthProfile;
}

/**
 * Authentication user profile interface.
 */
export interface AuthProfile {
  /** Authenticated user username. */
  username: string;
  /** Authenticated user display name (full name or such). */
  displayName: string;
}

/**
 * @hidden
 * Authentication query param enum.
 */
 export enum AuthQueryParam {
  /** Authentication request query param name. */
  request = 'authreq',
  /** Authentication response query param name. */
  response = 'authres',
  /** Authentication redirect query param name. */
  redirection = 'authred',
  /** Authentication success query param name. */
  success = 'success',
  /** Authentication state query param name. */
  state = 'state',
  /** Authentication context param name. */
  context = 'authctx',
}

/**
 * @hidden
 * Authentication event type enum.
 */
export enum AuthEventType {
  /** Session end event type. */
  sessionStart = 'SESSION_START',
  /** Before session expiration event type. */
  beforeSessionExpiration = 'BEFORE_SESSION_EXPIRATION',
  /** Session expiration event type. */
  sessionExpiration = 'SESSION_EXPIRATION',
  /** Session end event type. */
  sessionEnd = 'SESSION_END',
  /** Before login event type. */
  beforeLogin = 'BEFORE_LOGIN',
  /** After login event type. */
  afterLogin = 'AFTER_LOGIN',
  /** Before parent login event type. */
  beforeParentLogin = 'BEFORE_PARENT_LOGIN',
  /** After parent login event type. */
  afterParentLogin = 'AFTER_PARENT_LOGIN',
  /** Before window login event type (uses event result). */
  beforeWindowLogin = 'BEFORE_WINDOW_LOGIN',
  /** After window login event type. */
  afterWindowLogin = 'AFTER_WINDOW_LOGIN',
  /** Before logout event type. */
  beforeLogout = 'BEFORE_LOGOUT',
  /** After logout event type. */
  afterLogout = 'AFTER_LOGOUT',
  /** Before parent logout event type. */
  beforeParentLogout = 'BEFORE_PARENT_LOGOUT',
  /** After parent logout event type. */
  afterParentLogout = 'AFTER_PARENT_LOGOUT',
  /** Before window logout event type (uses event result). */
  beforeWindowLogout = 'BEFORE_WINDOW_LOGOUT',
  /** After window logout event type. */
  afterWindowLogout = 'AFTER_WINDOW_LOGOUT',
}

/**
 * @hidden
 * Authentication request return by enum.
 */
 export enum AuthRequestReturnBy {
  redirect = 'REDIRECT',
  iframe = 'IFRAME',
}

/**
 * @hidden
 * Authentication redirection type enum.
 */
 export enum AuthRedirectionType {
  logout = 'LOGOUT',
}

/**
 * Login reason.
 */
export enum AuthLoginReason {
  loginFailed = 'loginFailed',
  sessionExpired = 'sessionExpired',
  codeExpired = 'codeExpired',
  notAuthorized = 'notAuthorized',
  errorOccurred = 'errorOccurred',
}

/**
 * @hidden
 * Login arguments interface.
 */
export interface AuthLoginArgs {
  /** Login method. */
  returnBy: typeof AuthRequestReturnBy.redirect | typeof AuthRequestReturnBy.iframe;
  /** Login return URL. */
  returnTo?: string;
  /** Username to initialize the login form with. */
  username?: string;
  /** Preserved client state. */
  state?: any;
  /** Login reason. */
  reason?: AuthLoginReason;
}

/**
 * Login redirect arguments interface.
 */
export interface AuthLoginRedirectArgs {
  /** Login return URL. */
  returnTo?: string;
  /** Username to initialize the login form with. */
  username?: string;
  /** Preserved client state. */
  state?: any;
  /** Login reason. */
  reason?: AuthLoginReason;
}

/**
 * Login iframe arguments interface.
 */
export interface AuthLoginIframeArgs {
  /** Username to initialize the login form with. */
  username?: string;
  /** Preserved client state. */
  state?: any;
  /** Login reason. */
  reason?: AuthLoginReason;
}

/**
 * Login return interface
 */
export interface AuthLoginReturn {
  /** Login success flag. */
  success: boolean;
  /** Preserved client state. */
  state: any;
}

/**
 * @hidden
 * Authentication login request interface.
 */
export interface AuthLoginRequest {
  env: AuthEnv;
  options: AuthOptions;
  returnBy: typeof AuthRequestReturnBy.redirect | typeof AuthRequestReturnBy.iframe;
  returnTo?: string;
  username?: string;
  reason?: AuthLoginReason;
  stateMap: any;
}

/**
 * @hidden
 * Authentication login response interface.
 */
export interface AuthLoginResponse {
  success: boolean;
  action?: AuthLoginAction;
  stateMap: any;
}

/**
 * @hidden
 * Authentication login action interface.
 */
export interface AuthLoginAction {
  type: 'REDIRECT';
  url?: string;
}

/**
 * Logout reason.
 */
export enum AuthLogoutReason {
  userAction = 'userAction',
  sessionExpired = 'sessionExpired',
  errorOccurred = 'errorOccurred',
}

/**
 * @hidden
 * Logout arguments interface.
 */
export interface AuthLogoutArgs {
  /** Login method. */
  returnBy: typeof AuthRequestReturnBy.redirect;
  /** Login return URL. */
  returnTo?: string;
  /** Preserved client state. */
  state?: any;
  /** Logout reason. */
  reason?: AuthLogoutReason;
  /** Logout message. */
  logMessage?: string;
}

/**
 * @hidden
 * Logout arguments interface.
 */
export interface AuthLogoutRedirectArgs {
  /** Login return URL. */
  returnTo?: string;
  /** Preserved client state. */
  state?: any;
  /** Logout reason. */
  reason?: AuthLogoutReason;
  /** Logout message. */
  logMessage?: string;
}

/**
 * Logout return interface
 */
 export interface AuthLogoutReturn {
  /** Logout success flag. */
  success: boolean;
  /** Preserved client state. */
  state: any;
}

/**
 * @hidden
 * Authentication logout request interface.
 */
export interface AuthLogoutRequest {
  env: AuthEnv;
  options: AuthOptions;
  returnBy: typeof AuthRequestReturnBy.redirect;
  returnTo?: string;
  reason?: AuthLogoutReason;
  logMessage?: string;
  stateMap: any;
}

/**
 * @hidden
 * Authentication logout response interface.
 */
 export interface AuthLogoutResponse {
  success: boolean;
  stateMap: any;
}
